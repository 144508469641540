<template>
  <div>
    <el-dialog title="请选择题目数量"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :modal='true'
               :append-to-body="true"
           
               :before-close="handleClose">
      <el-form :model="form"
               size="medium"
               ref="formRef"
               label-width="100rem">

        <el-form-item prop="discuss_title">
              <el-input-number v-model="point_num"
                               :min="1"
                               :max="10"
                               label="推送薄弱考点数"></el-input-number>

        </el-form-item>
        <div class="btn_warp">
          <div @click="handleClose">取消</div>
          <div @click="exportBtn">确定</div>
        </div>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>

import { saveAs } from "file-saver"
export default {
  data () {
    return {
      dialogVisible: false,
     
      form: {
      },
   
     
      point_num: 5,
      row:null,
    }
  },
  async created () {
   
  },
  methods: {
 
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    exportBtn () {
      this.$parent.doPaper(this.row)
      this.handleClose()
    },

  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23rem;
  font-size: 16rem;
  // padding: 27rem;
  padding-top: 0;
}

.btn_warp {
  width: 520rem;
  height: 62rem;
  border-top: 1rem solid #eeeeee;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0rem 0rem 0rem 0rem;
  opacity: 1;
  align-items: center;
  justify-content: center;

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 24rem;
    &:nth-child(1) {
      width: 40rem;
      height: 60rem;
      font-size: 20rem;

      font-weight: 400;
      border-right: 1rem solid #eeeeee;
      color: #333333;

      line-height: 30rem;
      -webkit-background-clip: text;
    }
    &:nth-child(2) {
      width: 40rem;
      height: 30rem;

      font-size: 20rem;

      font-weight: 400;
      color: #2196f3;
      line-height: 30rem;
      -webkit-background-clip: text;
    }
  }
}
::v-deep .el-checkbox__inner::after {
  height: 15rem;
  left: 7rem;
  width: 5rem;
  box-sizing: content-box;
  content: "";
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;

  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);

  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
::v-deep .el-dialog__body {
  display: flex;
  justify-content: center;
  height: 100rem;
}

::v-deep .el-dialog__header {
  text-align: center;
  font-size: 24rem;
  font-weight: 500;
  color: #333333;
  line-height: 36rem;
  -webkit-background-clip: text;
}
::v-deep .el-dialog {
  border-radius: 10rem;
  width: 520rem;
  height: auto;
  background: #ffffff;
  border-radius: 24rem 24rem 24rem 24rem;
  opacity: 1;
}

::v-deep .el-textarea__inner {
  font-size: 16rem;
}
::v-deep .el-form-item__content {
  margin-left: 37rem !important;
}

::v-deep .el-input__inner {
  // height: 45rem;
  line-height: 45rem;
  font-size: 16rem;
}
::v-deep .el-checkbox__input {
  padding-top: 4rem;
}
::v-deep .el-form-item__label {
  line-height: 45rem;
  font-size: 18rem;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__title {
  width: 144rem;
  height: 36rem;
  font-size: 24rem;

  font-weight: 600;
  color: #333333;
  line-height: 36rem;
  -webkit-background-clip: text;
}

::v-deep .el-checkbox-group {
  text-align: left; //这个很关键，否则最后一行是居中状态
}
::v-deep .el-checkbox__inner {
  width: 23rem;
  height: 23rem;
}
::v-deep .el-checkbox__label {
  font-size: 20rem;
  padding-left: 8rem;
}
::v-deep .el-checkbox {
  // margin-left: 20rem !important;
  margin-right: 24rem;
  // width: 72rem; //根据内容设置宽度
  height: 30rem;
  // padding-left: 0rem;
  font-size: 20rem;
  margin-bottom: 24rem;
  text-align: left; //这个很关键，否则每一行都是居中状态
}
</style>